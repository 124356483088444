/* ==========================================================================
   #SELECT
   ========================================================================== */

.c-select {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: block !important;
  opacity: 0;

  @include mq($from: large) {
    pointer-events: none;
  }

  &__container {
    position: relative;
  }

  &__title {
    @include formInput();
    text-align: left;
    cursor: pointer;
    position: relative;

    padding-right: $inuit-global-spacing-unit + $inuit-global-spacing-unit-huge;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: $inuit-global-spacing-unit + $inuit-global-spacing-unit-huge;
      height: 100%;
      background: url('../assets/images/svg/chevron.svg') no-repeat center right $inuit-global-spacing-unit;
    }

    &.is-open:after {
      transform: rotateX(180deg);
    }
  }

  &__list {
    border: 1px solid color-var(border);
    margin-top: -1px;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: color-var(bg);

    &.is-open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__item {
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
    margin: 0;
    cursor: pointer;

    &:hover {
      background: color-var(bg, 5);
    }

    &:first-child {
      display: none;
    }

    &.is-selected {
      background: color-var(bg, 5);
    }
  }

  .c-label--disabled & {
    &__title {
      pointer-events: none;
      color: color-var(font, 6);
      background: color-var(bg, 5);
      border-color: color-var(bg, 5);

      &:focus,
      &:active {
        border-color: color-var(bg, 5);
      }
    }
  }

  &--small {
    width: auto;
    display: inline-block;
    margin-bottom: 0;

    .c-select {
      &__title {
        border: 0;
        width: auto;
      }
    }
  }
}
