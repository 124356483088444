.c-embed {
  &--video {
    position: relative;

    &:before {
      content: "";
      padding-top: 56.25%;
      display: block;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
