.c-newsletter {
  background: color-var('bg', 2);
  border-radius: $inuit-global-spacing-unit-tiny;
  padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-large;
  color: color-var(font, 3);
  text-align: center;
  position: sticky;
  top: 110px;
  line-height: 1.6em;

  .c-label {
    text-align: left;
    margin-bottom: $inuit-global-spacing-unit-tiny;

    &__title {
      font-size: 14px;
    }
  }

  .c-label--toggleable-arr {
    &:after {
      background-color: color-var(font, 3);
      background-image: none;
    }
  }

  &__title {
    font-weight: 500;
  }

  &__desc {
    font-size: $inuit-font-size-h5;
    line-height: 1.2em;
  }

  .c-titled-content & {
    display: none;
  }
}
