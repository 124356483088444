.c-simple {
  &__image{
    img{
      border-radius: 8px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
    }
  }
  &__desc {
    margin-bottom: $inuit-global-spacing-unit-big;
  }
  &__title {
    margin-bottom: $inuit-global-spacing-unit-small;
  }
  &--alternative {
    .c-simple__image {
      order: 2;

      @include mq($until: medium){
        margin-top: 15px;
      }
    }
  }
}
