.c-tag {
  display: inline-block;
  color: color-var(font, 3);
  @include inuit-font-size($inuit-font-size-h6 - 1px, 1.7em);
  padding: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-tiny;
  background: color-var(bg, 2);
  border-radius: $global-radius;

  &--alternative {
    color: color-var(font, 2);
    background: color-var(bg);
    border: 1px solid color-var(border);
    border-radius: 2px;
  }
}
