.c-slider {
  position: relative;
  &__pager {
    text-align: center;
    margin-top: $inuit-global-spacing-unit-great;
  }
  &__page {
    margin: $inuit-global-spacing-unit-micro;
    display: inline-block;
    @include square-style(15px);
    border-radius: 50%;
    background: color-var(bg, 2);
    cursor: pointer;
    opacity: 0.2;
    &:hover {
      opacity: 0.3;
    }
    &.is-active {
      opacity: 1;
    }
  }
  &--fade {
    display: flex;
    .c-slider {
      &__item {
        @include transition();
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-left: -100%;
        }
        opacity: 0;
        pointer-events: none;
        &.is-active {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
}
