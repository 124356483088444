/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: color-var(font, 4);
  text-decoration: underline;
  font-weight: $global-strong-font-weight;

  &:not(.c-button):hover {
    color: color-var(font);
  }
}
