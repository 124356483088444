.c-instagram {
  &__title,
  &__subtitle {
    margin-bottom: $inuit-global-spacing-unit-tiny;
    text-align: center;
  }
  &__item {
    margin-top: $inuit-global-spacing-unit-great;
  }
}
