/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-checkbox {
  @include clickableInput();

  &__replacement {
    border-radius: 2px;
    @include checkboxTick();
  }

  &__toggle {
    display: inline-block;
    width: 1.1em;
    height: 1em;
    margin-left: $inuit-global-spacing-unit-big;
    transform-origin: center center;

    .is-active & {
      transform: rotate(180deg);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      right: 0;
      height: 100%;
      width: 24px;
      background: url(../assets/images/svg/chevron.svg) no-repeat center right;
    }
  }

  &__message {
    display: none;
    font-size: $inuit-font-size-h6 - 2px;
    margin-top: $inuit-global-spacing-unit-small;

    .is-active & {
      display: block;
    }
  }

  .c-label__title {
    text-align: left !important;
  }
}
