.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.9);
  z-index: 99999;
  overflow: auto;

  &:not(.is-active) {
    display: none;
  }

  &__container {
    background: #fff;
    border-radius: 24px;
    padding: 30px;
    margin: 70px auto;
    position: relative;
  }

  &__footer {
    border-top: 1px color-var(border, 5) solid;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
  }

  &__buy {
    margin-left: 30px;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 999;
  }
}
