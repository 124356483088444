.c-post-list {
  &__title {
    margin-bottom: 0;
  }

  &__content {
    margin-top: $inuit-global-spacing-unit-small;
  }

  &__desc {
    &.c-post-list {

      &__desc,
      &__desc *:not(.c-button) {
        color: color-var(font) !important;
        font-size: $inuit-font-size-h5;
        margin-top: $inuit-global-spacing-unit;
        margin-bottom: 0;
      }
    }
  }

  &__cta {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &--reversed {
    margin-top: -$inuit-global-spacing-unit-huge * 6;
  }

  &--bordered {

    .c-tease,
    .c-article {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
      background: color-var(bg);
    }
  }

  &__item {
    margin-top: $inuit-global-spacing-unit-great;

    .c-article {
      height: 100%;
    }
  }
}
