.c-table {
  margin-top: $inuit-global-spacing-unit-huge;

  &__header,
  &__item {
    display: flex;
    width: 100%;
  }

  &__header {
    font-size: $inuit-font-size-h5;
    color: color-var(font, 7);
    border-top: 1px solid rgba(color-var(border, 2), 0.3);
    font-weight: $global-strong-font-weight;

    .c-table__cell {
      &:first-child {
        padding-left: 0;
      }
    }

    @include mq($until: large) {
      display: none;
    }
  }

  &__item {
    font-size: $inuit-font-size-h6;
    border-radius: 8px;
    border: 1px solid rgba(color-var(border, 1), 0.3);
    overflow: hidden;
    margin-bottom: $inuit-global-spacing-unit;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;


    @include mq($until: large) {
      display: flex;
      flex-wrap: wrap;
    }

    .c-table__cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:first-child {
        background: color-var(bg, 5);
        justify-content: center;
        padding: 0;
        text-align: center;
      }

      &:last-child {
        justify-content: center;
      }
    }
  }

  &__cell {
    padding: $inuit-global-spacing-unit-big;
    position: relative;

    &--small {
      width: 11.42%;
    }

    &--middle {
      width: 15.86%;
    }

    &--big {
      width: 29.58%;
    }

    @include mq($until: large) {
      flex: 0 0 100%;
      text-align: center;
      justify-content: center !important;
      padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-big;

      p {
        flex: 0 0 100%;
      }

      .u-text--prefix {
        position: static;
        display: block;
      }
    }
  }
}
