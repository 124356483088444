.c-icons {

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__content {
    margin-top: $inuit-global-spacing-unit-large;

    @include mq($until:large) {
      max-width: 100%;
      margin-left: 0;

      .c-icons__item {
        padding: 0;
      }
    }
  }

  &__icon {
    // height: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit;
    max-width: 90%;
    height: auto;

    &-desc {
      max-width: 80%;
      margin: auto;
    }

  }

  &__item {
    // display: flex;
    align-self: center;
  }

  &__cta {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &[class*="u-font"] {

    .c-icons__desc p,
    .c-icons__icon-desc {
      color: color-var(font) !important;
    }
  }

  &--bordered {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px;
    padding: $inuit-global-spacing-unit-massive $inuit-global-spacing-unit-huge;

    .c-icons {
      &__icon {
        height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit/2;

        &-desc {
          margin: $inuit-global-spacing-unit-huge auto;
          font-size: $inuit-font-size-h5;
        }

        &--big {

          height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-large;
        }
      }
    }
  }
}
