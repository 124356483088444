/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-icon {
  display: inline-block;

  @for $i from 0 through 8 {
    $deg: $i * 45;

    &--rotate-#{$deg} {
      transform: rotate(#{$deg}deg);
    }
  }

  position: absolute;
  left: $inuit-global-spacing-unit-tiny;
}
