.c-trainings {
  &__tab {
    padding: $inuit-global-spacing-unit-big;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgba(color-var(border, 1), 0.1);
    margin-bottom: -1px;
    position: relative;
    z-index: 0;

    &.is-active {
      border: 1px solid color-var(border, 2);
      border-bottom: 1px solid color-var(bg, 5);
      background: color-var(bg, 5);
      z-index: 2;
    }

    &-item {
      position: relative;
      // z-index: 1;
      border-radius: 8px;
      border-top-left-radius: 0px;
      border: 1px solid color-var(border, 2);
      padding: $inuit-global-spacing-unit-large;
    }
  }
}
