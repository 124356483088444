.c-content-tabs {
  &__item {
    display: none;

    &.is-active {
      display: block;
    }

    @media print {
      display: block !important;
      page-break-inside: avoid;
    }

    @include mq($until: large) {
      display: block !important;
    }
  }
}
