@media (max-width: 768px) {
  .wpml-ls-menu-item {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  .c-main-nav {
    text-align: center;
  }

  .wpml-ls-last-item a:before {
    color: #000 !important;
  }
}

.wpml-ls-menu-item a:after {
  display: none !important;
}

.wpml-ls-current-language a,
.wpml-ls-menu-item a:hover {
  color: #6deed8 !important;
}

.c-score__logo {
  top: 10px;
  right: 20px;
}

.c-score__desc {
  min-height: 5em;
}

.u-recapchta-info {
  padding: 15px 0;
  max-width: 300px;
  font-size: 0.6em !important;
  opacity: 0.7;
}

.grecaptcha-badge {
  visibility: hidden;
}

.u-image-border-accent{
  position: relative;
  .o-link{
    z-index: 0;
  }
  .c-post-list__item{
    .u-image-border-accent__item{
      border-radius: 8px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
      margin-bottom: 10px;
      &:before {
        @include absolutePseudoContent();
        width: 100%;
        height: 100%;
        border: 1px solid color-var(border, 2);
        pointer-events: none;
        top: -8px;
        left: -8px;
        border-radius: 8px;
        z-index: -1;
      }
      img{
        border-radius: 8px;
        position: relative;
      }
    }
  }

  .c-post-list__item:nth-child(even){
    .u-image-border-accent__item{
      &:before {
        border: 1px solid #FDC300;
        bottom: -8px;
        right: -8px;
        left: unset;
        top: unset;
      }
    }
  }
  .c-post-list__item:nth-child(odd){
    .u-image-border-accent__item{
      &:before {
        border: 1px solid color-var(border, 2);
        top: -8px;
        left: -8px;

      }
    }
  }
}
