/* ==========================================================================
   #tease
   ========================================================================== */

.c-tease {
  $self: &;
  display: block;
  position: relative;
  text-decoration: none !important;
  @include inuit-font-size($inuit-font-size-h5);
  height: 100%;

  .o-link {
    text-decoration: none !important;
  }

  &:hover {
    color: inherit;
  }

  &__top {
    padding-bottom: $inuit-global-spacing-unit-small;
    border-bottom: 1px solid rgba(color-var(border, 2), 0.3);
  }

  &__title {
    text-align: center;
    margin-bottom: $inuit-global-spacing-unit;
  }

  &__desc {
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
    font-weight: 300;
  }

  &__ID {
    color: color-var(font, 4) !important;
    @include inuit-font-size($inuit-font-size-h6);
    font-weight: $global-strong-font-weight;
    padding-left: $inuit-global-spacing-unit-tiny;
  }

  &__content {
    @include inuit-font-size($inuit-font-size-h4);
  }

  &__excerpt {
    margin-bottom: 0;
    font-size: $inuit-font-size-h5;

    &--small {
      font-size: $inuit-font-size-h6;
      line-height: 1.6em;
    }
  }

  &__image {
    background-size: cover;
    background-position: center center;
    position: relative;
    display: block;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 59.26%;
    }

    &-elem {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &--horizontal,
  &--big {
    display: flex;
  }

  &--horizontal {

    #{$self}__desc,
    #{$self}__image {
      flex: 1;
    }

    #{$self}__image {
      max-width: 270px;
    }

    #{$self}__desc {
      padding: 0;
      padding-left: $inuit-global-spacing-unit;
    }
  }

  &--big {
    #{$self}__desc {
      flex: 7;
      padding: $inuit-global-spacing-unit;
      @include inuit-font-size($inuit-font-size-h4);
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      &:after {
        content: '';
        flex: 0 0 100%;
      }
    }

    #{$self}__meta,
    #{$self}__content {
      flex: 0 0 100%;
    }

    #{$self}__image {
      flex: 5;
      order: 2;

      &:before {
        padding-bottom: 100%;
      }
    }
  }

  &--promo {
    text-align: center;

    #{$self}__meta {
      justify-content: center;
    }
  }

  &--simple {
    background: transparent;
    @include inuit-font-size($inuit-font-size-h4);
    padding: $inuit-global-spacing-unit-tiny 0;
    border-bottom: $global-border solid color-var(border);

    #{$self}__title {
      margin-bottom: 0;
    }

    &:hover {
      #{$self}__title {
        color: color-var(font, 4);
      }
    }
  }

  &--team {
    padding: 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
    overflow: hidden;
    border-radius: 8px;

    @include mq($from: medium) {
      &:hover {
        .js-hover-show {
          display: block !important;
          position: absolute;
          top: 0;
          z-index: 111;
          background-color: #fff;
          height: 100%;
          width: 100%;
        }
      }
    }

    &.active {
      .js-hover-show {
        display: block !important;
        position: absolute;
        top: 0;
        z-index: 111;
        background-color: #fff;
        height: 100%;
        width: 100%;
      }
    }
  }

  &--team .c-tease {
    &__image {
      &:before {
        padding-bottom: 330/270*100%;
      }
    }

    &__desc {
      text-align: center;
      padding: $inuit-global-spacing-unit-large
    }

    &__additional-info {
      margin-top: $inuit-global-spacing-unit-big;
    }

    &__phone,
    &__email {
      display: block;
      text-align: center;
    }

    &__job {
      @include inuit-font-size($inuit-font-size-h6);
    }
  }


  &--training {
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;

    .c-tease {
      &__title {
        text-align: left;
        max-width: 70%;
        line-height: 1.5em;
        min-height: 4.5em;
      }

      &__content {
        padding: $inuit-global-spacing-unit-small 0;
        border-bottom: 1px solid rgba(color-var(border, 2), 0.3);
        margin-bottom: $inuit-global-spacing-unit;
      }
    }
  }

  &--testimonial {
    .c-tease {
      &__title {
        text-align: left;
        font-weight: 500;
        @include inuit-font-size($inuit-font-size-h6);
        margin-bottom: 0;
      }

      &__excerpt {
        @include inuit-font-size($inuit-font-size-h6);
        padding: $inuit-global-spacing-unit;
        padding-bottom: $inuit-global-spacing-unit-large;
        background: rgba(color-var(bg, 4), 0.06);
        border-radius: $global-radius*2;
        border-bottom-left-radius: 0;
        margin-bottom: 40px;
        position: relative;

        &:before {
          content: '';
          height: 1px;
          width: decimal-round(30px * sqrt(2), 1, ceil);
          background: color-var(bg, 4);
          position: absolute;
          left: -($inuit-global-spacing-unit-tiny - 1px);
          top: calc(100% + #{30px - $inuit-global-spacing-unit-tiny - 1px});
          transform-origin: bottom left;
          transform: rotate(-45deg);
        }

        &:after {
          content: '';
          border-left: 30px solid rgba(color-var(bg, 4), 0.06);
          border-bottom: 30px solid transparent;
          position: absolute;
          left: 0;
          top: 100%;
        }
      }

      &__effect {
        width: 100%;
        height: 100%;
        position: absolute;
        left: -$inuit-global-spacing-unit-tiny;
        top: -$inuit-global-spacing-unit-tiny;
        pointer-events: none;
        border: 1px solid color-var(bg, 4);
        border-radius: $global-radius*2;
        border-bottom: 0;
        border-bottom-left-radius: 0;

        &:after {
          content: '';
          width: 1px;
          height: 30px;
          background: color-var(bg, 4);
          position: absolute;
          left: -1px;
          top: 100%;
        }

        &:before {
          content: '';
          width: calc(100% - 30px);
          height: $global-radius*4;
          border-radius: $global-radius*2;
          border-bottom: 1px solid color-var(bg, 4);
          border-bottom-left-radius: 0;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      &__job {
        color: color-var(font, 7);
        font-weight: 500;
        @include inuit-font-size($inuit-font-size-h6);
      }
    }
  }

  &--technology {
    .c-tease {
      &__desc {
        text-align: center;
      }
    }

  }

  &--partner {
    .c-tease {
      &__image {
        height: auto;
        text-align: left;
        padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-large;

        &:before {
          display: none;
        }

        img {
          opacity: 1;
          max-height: 40px;
          width: auto;
          position: static;
        }
      }


      &__title {
        text-align: left;
        color: color-var(font, 4);
      }

      &__button {
        padding-top: $inuit-global-spacing-unit-large;
        padding-bottom: $inuit-global-spacing-unit-tiny;
      }
    }
  }
}
