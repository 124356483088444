.wp-block-quote {
  border: $global-border solid color-var(border);
  padding: $inuit-global-spacing-unit-large;
  line-height: 2em;
  color: color-var(font, 2);
  font-style: normal;

  &.is-style-large {
    border: 0;
    padding: 0;
    padding-left: $inuit-global-spacing-unit-massive;
    color: color-var(font);
    @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
    background: url(../assets/images/svg/quote.svg) no-repeat top $inuit-global-spacing-unit-tiny left;
  }

  p {
    margin: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

.wp-block {
  &[data-type^='acf'] {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:not([data-type^='acf']):not(.is-reusable) {
    max-width: 700px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      position: relative;
      font-weight: $global-heading-font-weight;
      font-family: $global-heading-font-family;
      margin-bottom: $inuit-global-spacing-unit;
      z-index: 1;
    }

    .post-type-post & {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
      }
    }

    img {
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    ul,
    ol {
      padding-left: $inuit-global-spacing-unit-great;
      list-style: none;
      counter-reset: list;
      margin: 0;
      margin-bottom: $inuit-global-spacing-unit-great;
      line-height: 2.25em;

      li {
        position: relative;
      }

      li:before {
        counter-increment: list;
        content: counter(list) '.';
        position: absolute;
        left: -$inuit-global-spacing-unit-great;
        width: $inuit-global-spacing-unit-great;
        text-align: center;
      }
    }

    ul li:before {
      content: '•';
      font-size: 1.5em;
      line-height: 1.5em;
    }

    p {
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    .post-type-post & {
      max-width: 670px;
    }

    &-gallery {
      .blocks-gallery-grid {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
      }

      @for $i from 1 through 8 {
        &.columns-#{$i} {
          .blocks-gallery-item {
            flex: 0 0 calc(100% / #{$i});
          }
        }
      }
    }
  }

  &[data-type='acf/titled-content']~.wp-block:not([data-type^='acf']):not(.is-reusable) {
    max-width: 1200px;
    padding: 0 $inuit-global-spacing-unit;
    font-size: $inuit-font-size-h5 !important;
  }

}
