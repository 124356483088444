@font-face {
  font-family: 'Geomanist';
  src: url('../assets/fonts/Geomanist/geomanist-book-webfont.eot');
  src: url('../assets/fonts/Geomanist/geomanist-book-webfont.eot?#iefix') format('embedded-opentype'),
          url('../assets/fonts/Geomanist/geomanist-book-webfont.woff2') format('woff'),
          url('../assets/fonts/Geomanist/geomanist-book-webfont.ttf') format('truetype'),
          url('../assets/fonts/Geomanist/geomanist-book-webfont.svg#Sri-TSCRegular') format('svg');
  font-weight: 500;
  font-style: normal;
}
