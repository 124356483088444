.c-image-block {
  width: 100%;
  background-color: color-var(bg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80%;
  border-radius: 8px;
  margin-bottom: $inuit-global-spacing-unit;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;


  &:before {
    content: "";
    padding-bottom: 100%;
    width: 100%;
    display: block;
  }
}
