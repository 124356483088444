.c-video {
  .c-embed {
    border-radius: 8px;
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
    z-index: 1;

    &.is-active {
      &:after {
        display: none;
      }
    }

    &:before {
      @include absolutePseudoContent();
      width: 100%;
      height: 100%;
      border: 1px solid color-var(border, 2);
      pointer-events: none;
      top: -8px;
      left: 8px;
      border-radius: 8px;
      z-index: -1;
    }

    &:after {
      content: "";
      width: 152px;
      height: 152px;
      border-radius: 50%;
      background: color-var(bg, 2) url('../assets/images/svg/play.svg') no-repeat center center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      pointer-events: none;

      @include mq($until:large) {
        width: 80px;
        height: 80px;
        background-size: 40px;
      }
    }
  }
}
