/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.c-heading {
  position: relative;
  font-weight: $global-heading-font-weight;
  font-family: $global-heading-font-family;
  margin-bottom: $inuit-global-spacing-unit;
  @include inuit-font-size($inuit-font-size-h4);
  z-index: 1;

  &--h0 {
    @include inuit-font-size($inuit-font-size-h0, $inuit-line-height-h0);

    @include mq($until:large) {
      @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
    }
  }

  &--h1 {
    @include inuit-font-size($inuit-font-size-h1, $inuit-line-height-h1);

    @include mq($until:large) {
      @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
    }
  }

  &--h2 {
    @include inuit-font-size($inuit-font-size-h2, $inuit-line-height-h2);

    @include mq($until:large) {
      @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
    }
  }

  &--h3 {
    @include inuit-font-size($inuit-font-size-h3, $inuit-line-height-h3);
  }
}
