.c-breadcrumbs {
  @include inuit-font-size($inuit-font-size-h6);
  color: color-var(font, 2);

  &__wrapper {
    padding: $inuit-global-spacing-unit 0;
    position: absolute;
    top: -$inuit-global-spacing-unit-huge;
    left: $inuit-global-spacing-unit;
  }

  a,
  span {
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
  }

  a {
    color: color-var(font);
    margin-right: $inuit-global-spacing-unit-tiny;
  }

  .breadcrumb {
    &_last {
      margin-left: $inuit-global-spacing-unit-tiny;
    }
  }

  &--alternative {
    position: static;
  }
}
