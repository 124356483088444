.c-faq {
  &__content {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px;
    padding: $inuit-global-spacing-unit-huge;
  }

  &[class*="u-font"] {

    .c-faq__list *,
    .c-faq__list {
      color: color-var(font) !important;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-bottom: $inuit-global-spacing-unit;

    &-item {
      margin-bottom: 0;
      width: 100%;

      &.is-active {
        .c-faq__title {
          background-image: url('../assets/images/svg/chevronUp.svg');

        }

        .c-faq__desc {
          display: block;
        }
      }
    }
  }

  &__title {
    font-weight: 700;
    padding: $inuit-global-spacing-unit 0;
    border-bottom: 1px solid color-var(border, 2);
    cursor: pointer;
    background: url('../assets/images/svg/chevronDown.svg') no-repeat center right 5px;
    padding-right: 25px;
  }

  &__desc {
    padding: $inuit-global-spacing-unit 0;
    font-size: $inuit-font-size-h5;
    display: none;
  }

  &__content {
    @include mq($until:large) {
      max-width: 100%;
      margin-left: 0;
      padding: 15px;
    }
  }
}
