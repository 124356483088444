.c-hero {
  position: relative;
  padding-bottom: $inuit-global-spacing-unit-massive;

  height: 200px;

  @include mq($until:large) {
    height: auto !important;
  }

  &:before {
    @include absolutePseudoContent();
    width: 100%;
    border-bottom: $inuit-global-spacing-unit-massive solid color-var(bg);
    border-left: $inuit-global-spacing-unit-massive solid transparent;
    pointer-events: none;
    z-index: 100;
    bottom: 0;
    left: 0;
  }

  &__title,
  &__subtitle {
    color: color-var(font, 3);
    margin-bottom: 0;
  }

  &__desc {
    max-width: 2/3*100%;

    @include mq($until:large) {
      max-width: 100%;
    }
  }

  &__content {
    text-align: center;
    width: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 100%;
  }

  &--xsmall {
    height: 240px;
  }

  &--small {
    height: 320px;
  }

  &--normal {
    height: 480px;
  }

  &--big {
    height: 600px;
  }

  &--full {
    height: 710px;
    padding-top: 110px;
    margin-top: -110px;
  }

  &--secondary {
    .c-hero {
      &__content {
        text-align: left;
      }

      &__container {
        justify-content: flex-start;
      }
    }
  }

  &:after {
    @include absolutePseudoContent();
    @include absPosition100();
    pointer-events: none;
  }


  @each $type,
  $colors in $baseColors {
    $iter: 1;

    @each $color in $colors {
      &.u-color--#{$type}-#{$iter}:after {
        background-color: $color !important;
        opacity: 0.4;
      }

      $iter: $iter+1;
    }
  }

  &__video {
    position: absolute;
    @include absPosition100();
    pointer-events: none;
    overflow: hidden;

    .c-embed {
      position: relative;
      padding-bottom: 56.25%;
      /* 16:9 */
      height: 0;

      iframe {
        position: absolute;
        top: -70px;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__buttons {
    .c-button {
      margin-right: $inuit-global-spacing-unit-tiny;
    }
  }

  &__footer {
    background-color: color-var(bg, 5);
    padding: $inuit-global-spacing-unit-large 0;
    @include inuit-font-size($inuit-font-size-h5);
  }

  &--training {
    padding-top: $inuit-global-spacing-unit-large;
    padding-bottom: 0;

    &:before {
      top: 100%;
      bottom: auto;
      border-bottom: $inuit-global-spacing-unit-massive solid transparent;
      border-left: $inuit-global-spacing-unit-massive solid color-var(bg, 2);
    }

    .c-hero {
      &__container {
        align-items: stretch;
      }

      &__content {

        &-data {
          align-content: space-between;
        }

        &-wrapper {
          height: 100%;
        }
      }


      &__metadata {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: $inuit-global-spacing-unit-big;
      }

      &__rating {
        font-size: $inuit-font-size-h6;
        display: flex;
        align-items: center;
        opacity: 0.7;

        @include mq($from: large) {
          margin-left: $inuit-global-spacing-unit;
        }
      }

      &__stars,
      &__stars-fill {
        height: $inuit-global-spacing-unit;
        width: 70px;
        background: url('../assets/images/svg/star.svg');
        background-repeat: repeat space;
        background-size: 14px;
      }

      &__stars {
        margin-right: $inuit-global-spacing-unit-micro;
      }

      &__stars-fill {
        background-image: url('../assets/images/svg/star_white.svg');
      }

      &__id {
        font-size: $inuit-font-size-h6;
        font-weight: 500;
        margin-bottom: 0;
      }

      &__partners {
        max-height: 100px;
        position: relative;
        z-index: 100;

        @include mq($until: large) {
          display: flex;
          flex-wrap: wrap;

          p {
            flex: 0 0 100%;
          }

          .c-image-block {
            flex: 0 0 calc(33.33% - #{$inuit-global-spacing-unit-micro});
            margin-right: $inuit-global-spacing-unit-micro;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &__container {
        z-index: 101;
      }
    }

    .c-breadcrumbs__wrapper {
      padding-top: 0;
    }
  }
}
