.c-sidebar-menu {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
  position: sticky;
  top: 110px;

  &__item {
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
    cursor: pointer;
    position: relative;

    &:hover {
      background: color-var(bg, 5);
    }

    &.is-active {
      background: color-var(bg, 2);
      color: color-var(font, 3);

      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 9px;
        mask-image: url('../assets/images/svg/chevronUp.svg');
        background: color-var(bg);
        transform: rotate(90deg);
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
      }
    }
  }
}
