.c-contact {
  position: relative;
  padding-bottom: 64px;
  text-align: center;

  &__title,
  &__subtitle {
    color: color-var(font, 3);
    margin-bottom: 0;
  }

  &__content {
    text-align: center;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    min-height: 480px;
    border-radius: 8px;
    padding: 64px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15) !important;
    background: no-repeat center center;

    @include mq($until:large) {
      padding: 0 15px;
    }

    &-wrapper {
      position: relative;

      &:before {
        @include absolutePseudoContent();
        width: calc(100% - #{$inuit-global-spacing-unit * 2});
        height: 100%;
        border: 1px solid color-var(border, 2);
        pointer-events: none;
        top: -8px;
        left: 8px + $inuit-global-spacing-unit;
        border-radius: 8px;
        z-index: -1;
      }
    }

    &:after {
      @include absolutePseudoContent();
      @include absPosition100();
      pointer-events: none;
      z-index: -1;
      border-radius: 8px;
    }

    @each $type,
    $colors in $baseColors {
      $iter: 1;

      @each $color in $colors {
        &.u-color--#{$type}-#{$iter}:after {
          background-color: $color !important;
          opacity: 0.4;
        }

        $iter: $iter+1;
      }
    }
  }

  &__desc {

    a {
      text-decoration: none;
      @include inuit-font-size($inuit-font-size-h4 + 2px);

      strong {
        font-weight: 700;
      }
    }
  }

  &--alternative {
    .c-contact {
      &__content {
        text-align: left;
      }

      &__container {
        justify-content: flex-start;
      }
    }
  }

  &--small {

    .c-contact {
      &__container {
        min-height: 260px;
      }
    }
  }
}
