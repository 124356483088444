/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: color-var(bg);
  position: relative;
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  body.admin-bar & {
    top: 32px;
  }

  &__wrapper {
    min-height: 70px;
    position: relative;
  }

  .t-transparent-header & {
    background: transparent;
    color: color-var(font, 3);
  }

  &__mobile-menu {
    padding-bottom: 15px;
    background: color-var(bg, 5);
    display: none;

    &.is-active {
      display: block;
    }

    &-trigger {
      cursor: pointer;

      .is-open {
        display: none;
      }

      .is-closed {
        display: flex;
      }

      &.is-active {
        .is-open {
          display: flex;
        }

        .is-closed {
          display: none;
        }
      }
    }
  }
}
