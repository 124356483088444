.c-color-box {
  min-height: 100px;
  margin: $inuit-global-spacing-unit-small 0;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-styleguide-section {
  padding: $inuit-global-spacing-unit-small 0;
}

.page-template-styleguide {
  background: darken(color-var(bg, 5), 10%);
}
