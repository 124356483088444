.page-template-page-order {
  .af-field-ks-term {
    @include inuit-hidden-visually();
  }

  .af-field-type-group {
    border: 0 !important;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    padding: 30px 70px 40px !important;
    margin: 15px 0 !important;
    display: block;

    @include mq($until: large) {
      padding: 15px !important;
    }

    &>.acf-label {
      font-family: $global-heading-font-family;
      color: color-var(font, 4);
      text-align: center;
      margin: 0 0 $inuit-global-spacing-unit;

      label {
        font-weight: 500;
        font-size: $inuit-font-size-h4 + 4px;
      }
    }

    .acf-field-radio {
      &>.acf-label {
        color: color-var(font, 6);
        font-size: $inuit-font-size-h6;
      }

      .acf-required {
        display: none;
      }
    }

    .acf-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &.-border {
        border: 0;
      }

      &>.acf-field {
        border: 0;
      }
    }

    .acf-label {
      font-size: $inuit-font-size-h5;
    }

    .acf-input input[type='text'],
    .acf-input input[type='email'] {
      @extend .c-input;
    }

    .acf-field {
      padding: 0;
      flex: 0 0 100%;
      margin-bottom: 15px;

      &[data-name$='_building'],
      &[data-name$='_room'] {
        flex: 0 0 48%;

        @include mq($until: large) {
          flex: 0 0 100%;
        }
      }

      &[data-name='ks_invoice form'],
      &[data-name='ks_invoice_einvoice_agreement'] {
        .acf-label {
          display: none;
        }
      }

      &-message {
        .acf-input {
          font-size: 13px;
        }
      }
    }
  }

  .acf-form {
    &>.acf-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      &>* {
        flex: 0 0 100%;
      }

      .af-field[data-name$='_data'] {
        flex: 0 0 48.5%;

        @include mq($until: large) {
          flex: 0 0 100%;
        }
      }
    }
  }

  .af-field-ks-invoice-form {
    &>.acf-input {
      width: calc(50% - 90px);

      @include mq($until: large) {
        width: 100%;
      }
    }
  }

  ul.acf-radio-list li,
  ul.acf-checkbox-list li {
    font-size: $inuit-font-size-h5;
    line-height: 2.2em;
  }

  .acf-true-false {
    .message {
      font-size: $inuit-font-size-h6;
      line-height: 1.2em;
    }

    .c-checkbox__replacement {
      top: 4px;
    }
  }

  .af-submit-button {
    @extend .c-button;
    @extend .c-button--primary;
    margin: auto;
  }

  .acf-form-submit {
    text-align: center;
    margin-bottom: $inuit-global-spacing-unit-massive;
  }

  .af-success {
    padding: 40px 70px;
    border: 0 !important;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.15);

    @include mq($until: large) {
      padding: 15px;
    }

    tr,
    td,
    th {
      border: 0 !important;
      padding: 0;
      font-size: $inuit-font-size-h5;

      &:empty {
        display: none;
      }
    }

    th {
      color: color-var(font, 4);
      font-weight: 500;
      padding-bottom: 15px;
    }

    &>.af-field-include {
      &>tbody {

        &>tr:first-child,
        &>tr:nth-child(2) {
          display: none;
        }

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, auto);

        &>tr:nth-child(3) {
          grid-area: 1 / 1 / 2 / 2;
        }

        &>tr:nth-child(4) {
          grid-area: 2 / 1 / 3 / 2;
        }

        &>tr:nth-child(5) {
          grid-area: 1 / 2 / 2 / 3;
        }

        &>tr:nth-child(6) {
          grid-area: 2 / 2 / 3 / 3;
        }

        &>tr:nth-child(7) {
          grid-area: 1 / 3 / 2 / 4;
        }

        &>tr:nth-child(8) {
          grid-area: 2 / 3 / 3 / 4;
        }

        &>tr:nth-child(9) {
          grid-area: 3 / 1 / 4 / 4;
        }

        &>tr:nth-child(10) {
          grid-area: 4 / 1 / 5 / 4;
        }

        @include mq($until: large) {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          grid-auto-flow: row;
        }
      }
    }

    .af-field-include-group {
      th {
        display: none;
      }
    }
  }

  .acf-checkbox-list {
    li {
      padding-bottom: $inuit-global-spacing-unit;

      label {
        width: 100%;
        font-size: $inuit-font-size-h6;
        line-height: 1.5em;
        display: inline-block;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        @include mq($until: large) {
          padding-right: 25px;
        }

      }

      &:not(:first-child) {
        padding-left: $inuit-global-spacing-unit-huge;

        @include mq($until: large) {
          padding-left: 0;
        }
      }
    }
  }
}

.c-checkbox__toggle {
  position: absolute;
  top: 0.2em;
  right: 0;
}
