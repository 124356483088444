/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  @include inuit-font-size($inuit-font-size-h5);
  color: color-var(font, 2);

  @include mq($until:large) {
    flex-wrap: wrap;
  }

  &--search {
    justify-content: flex-end;
  }
}

.c-main-nav__item {
  list-style: none;
  margin: 0;
  margin-left: $inuit-global-spacing-unit-huge;

  &:hover .c-main-nav__dropdown {
    display: flex;
  }

  @include mq($until:large) {
    flex: 0 0 100%;
    text-align: center;
    margin-left: 0;
  }
}

.c-main-nav__link {
  display: block;
  padding: $inuit-global-spacing-unit-big 0;
  text-decoration: none;
  color: color-var(font);
  font-weight: 400;

  &:hover {
    color: color-var(font, 4);
  }
}

.current-menu-item .c-main-nav__link {
  color: color-var(font, 4);
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  width: 100%;
  background: color-var(bg, 5);
  padding: $inuit-global-spacing-unit-large 0;

  &:before {
    @include absolutePseudoContent();
    bottom: 100%;
    left: 0;
    width: 100%;
    height: $inuit-global-spacing-unit-large;
  }
}

.c-main-nav__list,
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  color: color-var(font);

  &:hover {
    color: color-var(font, 4);
  }
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: color-var(border);
}
