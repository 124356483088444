.c-testimonials {
  &__item {
    box-shadow: $global-shadow;
    padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-massive;
    .wp-block-quote {
      padding-left: $inuit-global-spacing-unit-huge +
        $inuit-global-spacing-unit-big;
      margin: $inuit-global-spacing-unit-big (-$inuit-global-spacing-unit-small)
        0;
      p {
        @include inuit-font-size($inuit-font-size-h4, $important: true);
      }
    }
  }
  &__author {
    font-weight: $global-strong-font-weight;
    text-align: right;
    margin-top: $inuit-global-spacing-unit-tiny !important;
  }
}
