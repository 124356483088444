.c-excerpt {
  border: 1px color-var(border, 5) solid;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 30px;

  &.is-active {
    border: 2px color-var(border, 2) solid;
  }

  &__tick-container {
    background: color-var(bg, 6);
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__price {
    flex: 1;
    text-align: center;
  }

  @include mq($from: large) {
    &--side {
      display: flex;
      align-items: center;

      .c-excerpt {
        &__tick-container {
          width: 70px;
          flex: 0 0 70px;
          height: auto;
          align-self: stretch;
        }

        &__price {
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }

  .c-tease {
    &__top {
      border-bottom: 0;
    }

    &__title {
      height: 3.6em;
      line-height: 1.2em;
      display: flex;
      align-items: center;
      text-align: left;
    }

    &__excerpt:not(:empty) {
      height: 6.5em;
      font-size: 14px;
      line-height: 1.3em;
      margin-bottom: $inuit-global-spacing-unit;
    }
  }
}

.c-tick {
  @include square-style(28px);
  border: 2px solid color-var(border, 2);
  background: color-var(bg);
  border-radius: 50%;
  position: relative;

  &.is-active {
    @include excerptTick(true);
  }
}
