.c-widget {
  &__title {
    @include inuit-font-size($inuit-global-font-size);
    font-weight: 400;
    margin-bottom: $inuit-global-spacing-unit;
  }
  &__article {
    margin-bottom: $inuit-global-spacing-unit-great;
  }
}
